import { logger } from '@fiverr-private/obs';

/*
 * Needed for cases when a Perseus import something from futile.
 * Futile's index file includes an import to the Futile's gofor wrapper.
 * Futile's gofor wrapper is creating an instance of Gofor in the main scope of the file (while importing).
 * The following code is needed to prevent CSR errors and also for reporting about gofor usages.
 */
if (!window.Gofor) {
    window.Gofor = function Gofor() {
        return {
            fetch: (url: string) => {
                logger.error(new Error('[layout-sdk] - Gofor usage is not allowed'), {
                    code: 'GOFOR_MISSING_ERROR',
                    urlToFetch: url,
                });
            },
        };
    };
}
