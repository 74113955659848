/**
 * Required for establishing a socket connection.
 */
export const REALTIME_CONNECTION_EVENT = 'websocket_authentication';

/**
 * The default controller to use.
 */
export const DEFAULT_CONTROLLER = 'v5';

/**
 * The default action to use.
 */
export const DEFAULT_ACTION = 'general';

/**
 * A human readable error message, for all
 * socket connection related errors.
 */
export const FAILED_TO_INITIALIZE = 'Failed to initialize a web socket connection';

/**
 * The endpoint which should be used
 * to tell the server we wish to establish a
 * connection, and provides us the configurations
 * we need in order to do so.
 */
export const SOCKET_CONFIG_URL = '/realtime/websocket';

/**
 * Used to enrich errors.
 */
export const MODULE_NAME = 'global scripts';
