import { logger } from '@fiverr-private/obs';
import * as cookieHelper from '@fiverr-private/layout-lib/cookie';
import { REPEAT_SOLVE_COOKIE_NAME, REPEAT_SOLVE_COOKIE_SEPARATOR, EXONERATION_EXPIRATION } from '../constants';

/**
 * Check if this user has been exonerated before the expiration.
 * @param request Intercepted request.
 */
export function reportInterceptAfterSolve(request: Request): void {
    const cookies = cookieHelper.getAll();
    const cookie = cookies && cookies[REPEAT_SOLVE_COOKIE_NAME];
    if (!cookie) {
        return;
    }

    const [iterations, beginning] = cookie.split(REPEAT_SOLVE_COOKIE_SEPARATOR).map(Number);
    const interval = Date.now() - beginning;

    if (interval < EXONERATION_EXPIRATION) {
        logger.force.warn({
            message: `Advanced block event after challenge solve: ${iterations} in ${Math.round(
                interval / 1000
            )} seconds.`,
            interval,
            code: 'REPEAT_CHALLENGE_SOLVE',
            url: request.url,
            vid: cookies._pxvid,
            u_guid: cookies.u_guid,
            referrer: document.referrer,
        });
    }
}

/**
 * Update/add cookie on exoneration events.
 */
export function updateSolveSuccess(): void {
    const cookie = cookieHelper.get(REPEAT_SOLVE_COOKIE_NAME);
    const [iterations = 0, beginning = Date.now()] = cookie
        ? cookie.split(REPEAT_SOLVE_COOKIE_SEPARATOR).map(Number)
        : [];
    const end: number = beginning + EXONERATION_EXPIRATION;

    cookieHelper.set(REPEAT_SOLVE_COOKIE_NAME, [iterations + 1, beginning].join(REPEAT_SOLVE_COOKIE_SEPARATOR), {
        expires: new Date(end),
        maxAge: Math.floor((end - Date.now()) / 1000),
        domain: '.fiverr.com',
        path: '/',
        sameSite: 'none', // cross-origin
        secure: true,
    });
}
