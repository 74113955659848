/**
 * Execute a callback once, before the page is hidden.
 */
export function beforeHide(callback) {
	function visibilitychangeCallback() {
		if (document.visibilityState !== "hidden") return;
		document.removeEventListener("visibilitychange", visibilitychangeCallback);
		callback();
	}
	document.addEventListener("visibilitychange", visibilitychangeCallback, {
		once: true,
	});
	document.addEventListener("pagehide", callback, { once: true }); // Safari
}
