import { describe } from "../describe";

/**
 * Safelytry to stringify structures with HTML elements
 */
export function stringify(target: any): string | undefined {
	try {
		return JSON.stringify(target, replacer);
	} catch (error) {
		setTimeout(() => {
			error.source = [`${target}`, typeof target].join(" ");
			throw error;
		});
		return undefined;
	}
}

/**
 * Replacer function for JSON.stringify which turns DOM Elements into string representations
 */
function replacer(_, value: any): any {
	if (value instanceof Element) {
		return describe(value);
	}
	return value;
}
