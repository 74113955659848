import { Interceptor } from '@fiverr-private/obs';
import { mandatoryScriptFailed } from '../mandatoryScriptFailed';

/**
 * Mute log in case a mandatory script failed to load.
 * @param interceptor - OBS logger interceptor.
 */
export const mandatoryScriptLoggerInterceptor = (interceptor: Interceptor) => {
    if (mandatoryScriptFailed()) {
        interceptor.mute();
    }
};
