import Cookie, { CookieSetOptions } from 'universal-cookie';
import { DOMAIN_NAME, DEFAULT_PATH } from './constants';

type HeaderCookies = string | Record<string, string>;

/**
 * Gets all available cookies per environment.
 * Cookies source can be explicit passed via cookies object or by default with document cookie object.
 * @param headerCookies The current request header cookies (relevant for SSR only).
 */
const cookiesSource = (headerCookies?: HeaderCookies) => {
    const cookieSource = typeof document === 'undefined' ? headerCookies : document.cookie;

    if (!cookieSource) {
        return {};
    }

    return cookieSource;
};

/**
 * Gets all available cookies per environment.
 * @param [headerCookies] The current request header cookies (relevant for SSR only).
 */
export const getAll = (headerCookies?: HeaderCookies): Record<string, string> => {
    const source = cookiesSource(headerCookies);

    return new Cookie(source).getAll();
};

/**
 * Gets a given cookie name from the available cookies source
 * @param name - The cookie name to get.
 * @param headerCookies - The current request header cookies (relevant for SSR only).
 */
export const get = (name: string, headerCookies?: HeaderCookies): string | undefined => {
    const source = cookiesSource(headerCookies);

    return new Cookie(source).get(name);
};

/**
 * Sets a given cookie value over fiverr domain (relevant for CSR only) .
 * @param name The cookie's name.
 * @param value The cookie's value.
 * @param options Cookie options, if a number is passed in it will be used to calculate "expires" option
 */
export const set = (name: string, value: unknown, options: Partial<CookieSetOptions> | number = {}) => {
    if (typeof options === 'number') {
        // Passed "expires-in"
        options = {
            expires: new Date(Date.now() + options),
        };
    }

    options.domain = options.domain || DOMAIN_NAME;
    options.path = options.path || DEFAULT_PATH;

    new Cookie().set(name, value, options);
};

/**
 * Removes a given cookie name from document's cookies
 * @param name The name of the cookie to be removed.
 * @param options Cookie options
 */
export const remove = (name: string, options?: Partial<CookieSetOptions>): void => new Cookie().remove(name, options);
