import { entries } from "../entries";

/**
 * Create a string representation of the element
 */
export function describe(element: Element | string): string {
	if (!element) return;
	if (typeof element === "string") return element;
	try {
		return (
			[nodeType(element), contents(element)].filter(Boolean).join(" ") ||
			`${element}`
		);
	} catch (error) {
		// Fallback to simple string representation
		return `${element}`;
	}
}

/**
 * Get the node type name of the element
 */
const nodeType = (element: Element): string =>
	typeof element.nodeType === "number"
		? entries(Node).find(
				([, enumerator]: [string, number]) => enumerator === element.nodeType,
			)[0]
		: undefined;
/**
 * Create a string representation of the element's content
 */
function contents(element: Element): string {
	if (element.nodeType === Node.TEXT_NODE) return element.nodeValue;
	if (typeof element.nodeName !== "string") return undefined;
	return querySelector(element);
}

/**
 * Create a string representation of the element's CSS selector
 */
function querySelector(element: Element): string {
	return [].reduce.call(
		element.attributes,
		(accumulator: string, { name, value }: Attr): string =>
			`${accumulator}[${name.toLowerCase()}${
				value ? `^="${value.substring(0, 50)}"` : ""
			}]`,
		element.nodeName.toLowerCase(),
	);
}
