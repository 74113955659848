import axios from 'axios';
import axiosInherit from 'axios-inherit';
import { getMetaTagValue } from '@fiverr-private/layout-lib/getMetaTagValue';
import interceptor from './interceptor';
import { CONTENT_TYPE_HEADER } from './constants';

Object.assign(axios.defaults.headers.common, CONTENT_TYPE_HEADER, {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': getMetaTagValue('csrfToken'),
    'Fvrr-Page-Ctx-Id': getMetaTagValue('ctx_id'),
});
Object.assign(axios.defaults.headers.post, CONTENT_TYPE_HEADER);
Object.assign(axios.defaults.headers.patch, CONTENT_TYPE_HEADER);
Object.assign(axios.defaults.headers.put, CONTENT_TYPE_HEADER);

axiosInherit(axios);
interceptor(axios);

window.axios = axios;
