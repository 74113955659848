import { assign } from "@recursive/assign";
import { name, version } from "../../package.json";
import type {
	Context,
	LogEntry,
	MonicoreEntry,
	RUMEntry,
	StatsEntry,
	SupportedEntryType,
} from "../types";

type EventList = (RUMEntry | MonicoreEntry | LogEntry | StatsEntry)[];

type Payload = {
	context?: Partial<Context>;
	rum?: RUMEntry[];
	monicore?: MonicoreEntry[];
	log?: LogEntry[];
	stat?: StatsEntry[];
};

/**
 * The eventStore is a global collection so separate instances of the SDK can share the same store
 */
const EVENT_STORES: symbol = Symbol.for(
	[
		name.split("/").pop(), // Name with namespace removed
		version.split(".").shift(), // Major version
	].join("_"),
);

export class EventStore {
	events: {
		[key in SupportedEntryType]:
			| RUMEntry[]
			| StatsEntry[]
			| LogEntry[]
			| MonicoreEntry[];
	};
	contexts: Partial<{
		[key in SupportedEntryType]: Partial<Context>;
	}>;

	/**
	 * Create a new event store to store events and contexts
	 * Grouped by event type, these events can be sent to the relay API in batches
	 */
	constructor() {
		this.events = Object.freeze({
			rum: [] as RUMEntry[],
			monicore: [] as MonicoreEntry[],
			log: [] as LogEntry[],
			stat: [] as StatsEntry[],
		});
		this.contexts = {};
	}

	/**
	 * Return the events and contexts as a single object, then empty the event store
	 */
	flush(): Payload {
		const { pendingRecordTypes } = this;
		if (pendingRecordTypes.length === 0) return null;
		const payload: Payload = pendingRecordTypes.reduce(
			(accumulator, type) =>
				Object.assign(accumulator, {
					[type]: [...(this.events[type] as EventList)].map((entry) => {
						entry._age = performance.now() - (entry._enteredAt ?? 0);
						delete entry._enteredAt;
						return entry;
					}),
				}),
			{
				context: assign(
					{},
					...pendingRecordTypes.map((type) => this.contexts[type]),
				),
			},
		);
		Object.keys(this.events).forEach((type) => {
			(this.events[type as SupportedEntryType] as EventList).length = 0;
		});
		return payload;
	}

	/**
	 * Add an event to the store by type
	 */
	add(type: SupportedEntryType, entries: EventList): this {
		entries.forEach((entry) => {
			entry._enteredAt = performance.now();
		});
		(this.events[type] as EventList).push(...entries);
		return this;
	}

	/**
	 * Add a context to the store by type
	 */
	setContext(type: SupportedEntryType, context: Context): this {
		this.contexts[type] = this.contexts[type] || {};
		Object.assign(this.contexts[type], context);
		return this;
	}

	/**
	 * Which event types have pending records
	 */
	get pendingRecordTypes(): SupportedEntryType[] {
		return Object.keys(this.events).filter(
			(key) => (this.events[key as SupportedEntryType] as EventList).length > 0,
		) as SupportedEntryType[];
	}

	/**
	 * Get the current batch size
	 */
	get batchSize(): number {
		return Object.entries(this.events).reduce(
			(accumulator: number, [_, events]: [string, EventList]): number =>
				accumulator + events.length,
			0,
		);
	}
}

/**
 * The eventStore is a global collection so separate instances of the SDK can share the same store
 */
export function getEventStore(pageId: string): EventStore {
	globalThis[EVENT_STORES] ||= new Map<string, EventStore>();
	if (globalThis[EVENT_STORES].has(pageId))
		return globalThis[EVENT_STORES].get(pageId);
	const eventStore = new EventStore();
	globalThis[EVENT_STORES].set(pageId, eventStore);
	return eventStore;
}
