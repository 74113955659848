/**
 * The site's default subdomain.
 */
export const DEFAULT_SUBDOMAIN = 'www';

/**
 * Layout related header namings.
 */
export const HEADERS = {
    CRAWLER_CLASSIFICATION: 'x-known-crawler-classification',
    USER_FIRST_VISIT: 'fvrr-gw-is-new-uid',
    EU_HEADER: 'x-cloudflare-is-eu',
};

/**
 * Cookies keyes
 */
export const COOKIES = {
    IOS_SMART_BANNER_HIDDEN: 'ios_smart_banner_hidden',
    ANDROID_SMART_BANNER_HIDDEN: 'android_smart_banner_hidden',
};

/**
 * User agents
 */
export const USER_AGENTS = {
    // eslint-disable-next-line max-len
    MOBILE: 'Mozilla/5.0 (iPhone; CPU iPhone OS 14_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/87.0.4280.77 Mobile/15E148 Safari/604.1',
    DESKTOP:
        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.150 Safari/537.36',
};

/**
 * The name property of meta tags, populating contextual information
 */

/**
 * The unique identifier of the page. Created on request
 */
export const META_TAG_NAME_CTX_ID = 'ctx_id';

/**
 * The name of the service creating the page
 */
export const META_TAG_NAME_PAGE_NAME = 'fiverrmeta:pagename';

/**
 * The name of the service creating the page
 * Same as PAGE_NAME
 */
export const META_TAG_NAME_FACILITY = 'fiverrmeta:facility';

/**
 * Minimum log level to log
 */
export const META_TAG_NAME_LOG_LEVEL = 'fiverrmeta:log_level';

/**
 * Unix timestamp in milliseconds representing the time the page was created.
 * Later used to calculate `hours_since_creation`
 */
export const META_TAG_NAME_CREATION_TIME = 'fiverrmeta:creation';

/**
 * User selected locale
 */
export const META_TAG_NAME_LOCALE = 'fiverrmeta:locale';

/**
 * User selected currency
 */
export const META_TAG_NAME_CURRENCY = 'fiverrmeta:currency';

/**
 * The id of the current logged-in user
 */
export const META_TAG_NAME_USER_ID = 'user_id';

/**
 * A sticky identifier for the visitor (cookie persistant)
 */
export const META_TAG_NAME_U_GUID = 'u_guid';
