import { beforeHide } from "./beforeHide";
import type { EventStore } from "./eventStore";
import { getEventStore } from "./eventStore";
import { Sender } from "./sender";
import type {
	Context,
	LogEntry,
	MonicoreEntry,
	Payload,
	RUMEntry,
	StatsEntry,
	SupportedEntryType,
} from "./types";
export type * from "./types";

export { Payload };

/**
 * The main SDK class for sending events to the Relay API
 */
export class RelaySDK {
	static SUPPORTED_EVENT_TYPES: SupportedEntryType[] = [
		"rum",
		"monicore",
		"log",
		"stat",
	];

	#sender: Sender;
	#eventStore: EventStore;
	#maxBatchSize: number;
	#maxBatchInterval: number;
	#timer: ReturnType<typeof setTimeout> | null = null;
	#localMode: boolean;

	constructor({
		id,
		endpoint,
		maxBatchSize = 25,
		maxBatchInterval = 5000,
		mode = "production",
	}: {
		/**
		 * Page ID, A.K.A Page CTX ID or Page Context ID
		 */
		id: string;
		/**
		 * HTTP address of the Relay API endpoint
		 */
		endpoint: string;
		/**
		 * Maximum number of events to send in a single batch
		 */
		maxBatchSize?: number;
		/**
		 * Maximum time to wait before sending a batch of events
		 */
		maxBatchInterval?: number;
		/**
		 * "local" mode will not send events to the API ("remote"). Instead, they will be logged to the console.
		 * For legacy code other strings are also supported.
		 */
		mode?:
			| "remote"
			| "local"
			| "prod" // Backwards compatibility
			| "production" // Backwards compatibility
			| "dev" // Backwards compatibility
			| "development" // Backwards compatibility
			| "test" // Backwards compatibility
			| "testing"; // Backwards compatibility
	}) {
		this.#sender = new Sender({ endpoint });
		this.#maxBatchSize = maxBatchSize;
		this.#maxBatchInterval = maxBatchInterval;
		this.#eventStore = getEventStore(id);
		this.#localMode =
			typeof mode === "string" ? /^(?:dev|test|local)/i.test(mode) : false;
		beforeHide(this.send.bind(this));
	}

	/**
	 * Send the current batch of events to the Relay API
	 * The same eventStore may be used by multiple instances of the SDK
	 */
	async send(): Promise<boolean> {
		this.#timer && clearTimeout(this.#timer);
		const payload = this.#eventStore.flush();
		this.#timer = null;
		if (!payload) return false;
		if (this.#localMode) {
			console.debug(`[RelaySDK] flush`, payload);
			return true;
		}
		return await this.#sender.send(payload);
	}

	/**
	 * Add one of the supported event types to the event store
	 */
	private addEntries(
		type: SupportedEntryType,
		entries: (RUMEntry | StatsEntry | LogEntry | MonicoreEntry)[],
	): this {
		this.#eventStore.add(type, entries);
		if (this.#eventStore.batchSize >= this.#maxBatchSize) {
			this.send();
			return this;
		}
		if (this.#timer === null) {
			this.#timer = setTimeout(this.send.bind(this), this.#maxBatchInterval);
		}
		return this;
	}

	/**
	 * Add a RUM performance record to be sent with the next batch
	 */
	rum(...entries: RUMEntry[]): this {
		return this.addEntries("rum", entries);
	}

	/**
	 * Add a Monicore record to be sent with the next batch
	 */
	monicore(...entries: MonicoreEntry[]): this {
		return this.addEntries("monicore", entries);
	}

	/**
	 * Add a log record to be sent with the next batch
	 */
	log(...entries: LogEntry[]): this {
		return this.addEntries("log", entries);
	}

	/**
	 * Add a stat record to be sent with the next batch
	 */
	stat(...entries: StatsEntry[]): this {
		return this.addEntries("stat", entries);
	}

	registerContext(type: SupportedEntryType, context: Context): this {
		this.#eventStore.setContext(type, context);
		return this;
	}
}
