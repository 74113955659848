/**
 * A list of support events to intercept.
 * @see https://github.com/fiverr/perimeterx-axios-interceptor
 */
export const INTERCEPTOR_EVENTS = {
    IGNORE: 'ignore',
    SUCCESS: 'success',
    FAILURE: 'failure',
    INTERCEPT: 'intercept',
};

/**
 * A mapping between an interceptor event type and its corresponding message.
 */
export const INTERCEPTOR_MESSAGES = {
    [INTERCEPTOR_EVENTS.IGNORE]: 'Axios ignored a PerimeterX block response',
    [INTERCEPTOR_EVENTS.SUCCESS]: 'Axios interceptor exonerated request',
    [INTERCEPTOR_EVENTS.INTERCEPT]: 'Axios intercepted a PerimeterX block response',
    [INTERCEPTOR_EVENTS.FAILURE]: 'Axios interceptor failed to exonerate request',
};

/**
 * Code field to log record.
 */
export const LOG_CODE = 'AXIOS_INTERCEPT_EVENT';

/**
 * Cookie name for PerimeterX Challenge Repeat Solve.
 */
export const REPEAT_SOLVE_COOKIE_NAME = '_pxcrs';

/**
 * Character used to separate parts of the cookie value.
 */
export const REPEAT_SOLVE_COOKIE_SEPARATOR = ':';

/**
 * Exoneration valid for 15 minutes.
 */
export const EXONERATION_EXPIRATION = 15 * 60 * 1000;
